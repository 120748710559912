<template>
  <v-container v-if="!loading" fluid class="">
    <v-card style="background-color: transparent !important;" :elevation="0" class="mb-16">
      <v-form>
        <v-row style="">
          <v-col cols="12" class="pa-0">
            <h1 class="text-center primary--text">DATOS EQUIPO</h1>
          </v-col>
          <v-col cols="12" md="4" class="py-1 d-flex justify-center">
            <v-file-input
              ref="pfp"
              accept="image/*"
              class="d-none"
              prepend-icon=""
              @change="processPfp"
            />
            <v-sheet @click.stop="addPfp" style="width: 256px;height: 256px;border-radius: 50% !important;cursor: pointer;overflow: hidden;">
              <v-icon size="256" v-if="!unidad.pfp">mdi-account</v-icon>
              <v-img
                v-else
                :src="'data:image/jpeg;charset=utf-8;base64,'+unidad.pfp"
                height="256"
                width="256"
              ></v-img>
            </v-sheet>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              @input="changed = true"
              class="v-superdense-input"
              dense
              style="width:100%"
              label="Nombre"
              outlined
              hide-details=""
              v-model="unidad.nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              @input="changed = true"
              class="v-superdense-input"
              dense
              style="width:100%"
              label="Apellidos"
              outlined
              hide-details=""
              v-model="unidad.apellidos"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-1 pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <h1 class="text-center primary--text">DATOS TÉCNICOS</h1>
          </v-col>
          <v-col cols="6" class="pb-0 px-0">
            <v-text-field
              class="v-superdense-input"
              dense
              :value="unidad.dias_trabajados"
              readonly
              label="Nº Servicios"
              outlined
              hide-details=""
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0 px-0">
            <v-text-field
              class="v-superdense-input"
              dense
              append-icon="mdi-calendar"
              :value="unidad.inicio ? new Date(unidad.inicio).toLocaleDateString('esp',{ month: 'short', year: 'numeric' }).toLocaleUpperCase() : 'No especificado'"
              readonly
              label="Inicio laboral"
              outlined
              hide-details=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-1 px-0">
            <v-checkbox
              @change="changed = true"
              class="ma-0"
              style="transform:scale(1.5,1.5);max-width: 0% !important"
              label="Coche"
              hide-details=""
              v-model="unidad.coche"
            ></v-checkbox>
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              label="Coche"
              outlined
              hide-details="auto"
              readonly
              :value="unidad.coche ? 'Sí' : 'No'"
            ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-1 pb-0 px-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0 px-0">
            <h1 class="text-center primary--text">DATOS LABORALES</h1>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              dense
              label="P. Evento"
              outlined
              hide-details="auto"
              readonly
              v-model="unidad.pEvento"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              dense
              label="P. Evento Top"
              outlined
              hide-details="auto"
              readonly
              v-model="unidad.pEventoTop"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              dense
              label="P. Montaje"
              readonly
              hide-details="auto"
              outlined
              v-model="unidad.pMontaje"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-1 px-0">
            <v-text-field
              dense
              label="Contratación"
              readonly
              hide-details="auto"
              outlined
              v-model="unidad.contratacion"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="error" fab @click="cancelar" large class="me-4" v-if="changed">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" fab @click="guardar" large>
        <v-icon>mdi-floppy</v-icon>
      </v-btn>
    </div>
  </v-container>
  <v-container v-else>
    <div style="width: 100%;height: 90vh;" class="d-flex align-center justify-center">
      <v-progress-circular
        size="250"
        indeterminate
        color="primary"
      >
        <h2>Cargando</h2>
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      unidad:{},
      changed:false,
      loading:false,
      menu:false,
    };
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.getEquipo()
    ])
    this.loading = false
  },
  methods: {
    async getEquipo() {
      const { data } = await axios({
        url: "/unidades_equipo/limited/"+this.$store.getters.getTokenExtraPayload.idUnidadEquipo,
      });
      this.unidad = data;
      this.changed = false
    },
    processPfp(img){
      if(img.type.match(/image.*/)){
        var reader = new FileReader();
        const self = this
        reader.onload = function (readerEvent) {
            var image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                var canvas = document.createElement('canvas'),
                    max_size = 512,
                    width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                self.$set(self.unidad,'pfp',canvas.toDataURL('image/jpeg').split(';base64,')[1]);
            }
            image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(img);
      }
      else{
        alert("Formato de archivo incorrecto")
      }
    },
    addPfp(){
      this.$refs.pfp.$refs.input.click()
    },
		guardar() {
      this.loading = true
      try {
        const data = axios({
          method: 'PUT',
          url: '/unidades_equipo/limited/' + this.$store.getters.getTokenExtraPayload.idUnidadEquipo, 
          data: this.unidad
        })
        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
        location.reload()
      } catch (error) {
        console.log(error);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
        this.loading = false
      }
		},
    async cancelar(){
      this.loading = true
      await Promise.all([
        this.getEquipo()
      ])
      this.loading = false
    }
  },
};
</script>

<style>
.day:hover{
  z-index:1000;
  border: none !important;
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75) !important;
  cursor:pointer
}
.fixed table{
  table-layout: fixed
}
</style>